import React, { useState, useEffect } from "react";
import HeaderSales from "../common/HeaderSales";
import Footer from "../common/Footer";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "axios";
import * as Constants from "../Constants/index";
import swal from "sweetalert";
import ResponsivePagination from "react-responsive-pagination";
import { saveAs } from "file-saver";
import Oval from "../../loader/CircleLoade";
import { useNavigate } from "react-router-dom";
import { color } from "@amcharts/amcharts5";

export default function EnrollkmentReport() {
  const navigate = useNavigate();
  // const currentMonth = moment().startOf('month');
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [results, setResults] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchRecord, setSearchRecord] = useState();
  const [limit] = useState("10");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState({});
	const [downloadLoading, setDownloadLoading] = useState(false);
	let token = localStorage.getItem("token");

  useEffect(() => {
    getReportData(
      {
        name: "",
        mobile: "",
        startDate: "",
        endDate: "",
      },
      1
    );
  }, []);

  const getReportData = async (data, pageNum) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      // const searchMobile = data.mobile === "" ? "" : `&mobile=${data.mobile}`;
      // const searchStatus = data.status === "" ? "" : `&status=${data.status}`;
      const searchName =
        data?.name !== undefined && data?.name !== ""
          ? `&name=${data?.name}`
          : "";
      const searchMobile =
        data?.mobile !== undefined && data?.mobile !== ""
          ? `&mobile=${data?.mobile}`
          : "";

      let stDate;
      let endDate;

      var momentObj1 = moment(data.startDate);
      var momentObj2 = moment(data.endDate);
      if (data.startDate && data.endDate) {
        var startFormattedDate = momentObj1.format("YYYY-MM-DD HH:mm:ss");
        var startFormattedDate4 = momentObj2.format("YYYY-MM-DD 23:59:59");
        stDate = `&start=${startFormattedDate}`;
        endDate = `&end=${startFormattedDate4}`;
      } else {
        stDate = "";
        endDate = "";
      }

      await axios
        .get(
          Constants.baseUrl +
            `reports/user/list?userType=MEMBER&sortBy=name&sortAsc=true${searchMobile}${stDate}${endDate}&limit=${limit}&page=${pageNum}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setResults(dt?.output?.results);
            setTotalPages(dt?.output?.totalPages);
            setLoading(false);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setResults(null);
            setTotalPages(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const download = (data) => {
    setLoading(true);
    setDownloadLoading(true);
    try {
      const searchMobile = data.mobile === "" ? "" : `&mobile=${data.mobile}`;
      const searchName = data.name === "" ? "" : `&name=${data.name}`;
      let stDate;
      let endDate;

      var momentObj1 = moment(data.startDate);
      var momentObj2 = moment(data.endDate);
      if (data.startDate && data.endDate) {
        var startFormattedDate = momentObj1.format("YYYY-MM-DD HH:mm:ss");
        var startFormattedDate4 = momentObj2.format("YYYY-MM-DD 23:59:59");
        stDate = `&start=${startFormattedDate}`;
        endDate = `&end=${startFormattedDate4}`;
      } else {
        stDate = "";
        endDate = "";
      }

      axios
        .get(
          Constants.baseUrl +
            `reports/download/userReport?userType=MEMBER${searchMobile}${searchName}${stDate}${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
            responseType: "blob", // Set the responseType to 'blob'
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            const fileData = new Blob([res.data], { type: "text/csv" });
            saveAs(fileData, "Enrollment_reports.csv");
            setDownloadLoading(false);
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            });
            setDownloadLoading(false);
          }
        });
    } catch (error) {
      console.log(error);
      setDownloadLoading(false);
    }
  };

  const searchFormik = useFormik({
    initialValues: {
      mobile: "",
      name: "",
      startDate: "",
      endDate: "",
    },
    validate: (data) => {
      let errors = {};
      if (data.mobile) {
        if (data.mobile.length < 10) {
          errors.mobile = "Mobile must be in 10 digits.";
        }
      }
      if (data.startDate === "" && data.endDate !== "") {
        errors.startDate = "Please select date";
      }
      if (data.startDate !== "" && data.endDate === "") {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "End date should be greater than start date";
      }
      // if (data.startDate && data.endDate) {
      //     const maxEndDate = new Date(data.startDate)
      //     maxEndDate.setDate(maxEndDate.getDate() + 30) // Adjust the range as needed (e.g., 31 days)

      //     if (data.endDate > maxEndDate) {
      //         errors.endDate = 'End date should be within 31 days from the start date.'
      //     }
      // }
      return errors;
    },
    onSubmit: (data) => {
      setCurrentPage(1);
      getReportData(data, 1);
      setFilterData(data);
    },
  });
  const isSearchFormFieldValid = (name) =>
    !!(searchFormik.touched[name] && searchFormik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small style={{color:'red'}}>
          {searchFormik.errors[name]}
        </small>
      )
    );
  };

  const resetData = () => {
    setCurrentPage(1);
    setFilterData({});
    searchFormik.resetForm();
    searchFormik.handleReset();
    getReportData(
      {
        name: "",
        mobile: "",
        startDate: "",
        endDate: "",
      },
      1
    );
  };

  return (
    <>
      {loading ? <Oval /> : null}
      <HeaderSales />
      <section className="blog-area pt182">
        <div className="container">
          <div className="row">
            <div className="col-md-12 tab mb-20">
              <ul className="nav nav-tabs" role="tablist">
                <li role="presentation" className="active">
                  <Link to="#Section1" role="tab" data-toggle="tab">
                    Enrollment Report
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="all-single-blog rpt">
                {/* <div className="row clearfix mb-15">
									<div class="col-md-3 col-sm-12 col-xs-12">
										<div class="form-group has-float-label style-two">
											<label>Mobile No.<sup>*</sup> </label>
											<input className="form-control" placeholder="Mobile no." type="text" maxLength='10' value={searchUser} onChange={handleSearchQuery} onKeyDown={handleKeyDown} />
										</div>
									</div>
									<div className="col-md-3 col-sm-12 col-xs-12">
										<div className="form-group has-float-label style-two">
											<label>From Date<sup>*</sup> </label>
											<div>
											</div>
											<DatePicker
												selected={fromDate}
												onChange={(date) => setFromDate(date)}
												dateFormat="dd-MM-yyyy"
												className="form-control"
												id="Fromdate"
												showYearDropdown
												showMonthDropdown
												autoComplete="off"
												scrollableYearDropdown
												maxDate={new Date()}
												onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
												onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
												onClick={(e) => e.preventDefault()} // Preven
											/>
										</div>
										{errors.fromDate && (
											<div className="error-message">{errors.fromDate}</div>
										)}
									</div>
									<div className="col-md-3 col-sm-12 col-xs-12">
										<div className="form-group has-float-label style-two">
											<label>To Date<sup>*</sup> </label>
											<DatePicker
												selected={toDate}
												onChange={(date) => setToDate(date)}
												dateFormat="dd-MM-yyyy"
												className="form-control"
												id="todate"
												showYearDropdown
												showMonthDropdown
												autoComplete="off"
												scrollableYearDropdown
												maxDate={new Date()}
												onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
												onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
												onClick={(e) => e.preventDefault()} // Preven
											/>
										</div>
										{errors.toDate && (
											<div className="error-message">{errors.toDate}</div>
										)}
									</div>
									<div className="col-md-12 col-sm-12 col-xs-12 text-center">
										<div className="form-group has-float-label style-two">
											<label className="invisible" style={{ width: "100%" }}>hidden</label>
											<input type="button" className="btn login_btn" value="Search" id="btnSearch" onClick={() => enrollReport(searchUser, currentPage)} />
										</div>
									</div>
								</div> */}

                <form onSubmit={searchFormik.handleSubmit}>
                  <div class="row">
                    <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                      <div class="row">
                        {/* <div className="col-md-3 col-sm-12 col-xs-12">
                          <div class="form-group">
                            <input
                              class="form-control reset"
                              type="text"
                              placeholder="Member Name"
                              id="name"
                              value={searchFormik.values.name}
                              onChange={(e) => {
                                
                                searchFormik.handleChange({
                                  target: {
                                    name: "name",
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                            {getSearchFormErrorMessage("mobile")}
                          </div>
                        </div> */}
                        <div className="col-md-4 col-sm-12 col-xs-12">
                          <div class="form-group">
                            <input
                              class="form-control reset"
                              type="text"
                              placeholder="Mobile No."
                              id="mobile"
                              value={searchFormik.values.mobile}
                              onChange={(e) => {
                                const numericValue = e.target.value
                                  .replace(/\D/g, "")
                                  .slice(0, 10);
                                searchFormik.handleChange({
                                  target: {
                                    name: "mobile",
                                    value: numericValue,
                                  },
                                });
                              }}
                            />
                            {getSearchFormErrorMessage("mobile")}
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                          <DatePicker
                            id="startDate"
                            selected={searchFormik.values.startDate}
                            onChange={(date) =>
                              searchFormik.setFieldValue("startDate", date)
                            }
                            style={{ zIndex: 999 }}
                            dateFormat="dd-MM-yyyy"
                            className="form-control calnder"
                            showYearDropdown
                            showMonthDropdown
                            autoComplete="off"
                            scrollableYearDropdown
                            maxDate={new Date()}
                            onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                            onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                            onClick={(e) => e.preventDefault()} // Preven
                            placeholderText="From date"
                          />
                          {getSearchFormErrorMessage("startDate")}
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                          <DatePicker
                            id="endDate"
                            selected={searchFormik.values.endDate}
                            onChange={(date) =>
                              searchFormik.setFieldValue("endDate", date)
                            }
                            dateFormat="dd-MM-yyyy"
                            className="form-control"
                            showYearDropdown
                            showMonthDropdown
                            autoComplete="off"
                            scrollableYearDropdown
                            maxDate={new Date()}
                            onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                            onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                            onClick={(e) => e.preventDefault()} // Preven
                            placeholderText="To date"
                          />
                          {getSearchFormErrorMessage("endDate")}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                      <div class="d-flex">
                        <button
                          type="submit"
                          class="btn login_btn"
                          //disabled={areAllValuesBlank()}
                        >
                          Search
                        </button>
                        <button
                          type="button"
                          class="btn login_btn"
													style={{ marginLeft: '20px' }}
                          onClick={() => resetData()}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <div className="row">
                  <div className="col-md-12">
                    <div className="carrd1">
                      <div className="card-header card-header-warning">
                        <h4 className="card-title">
                          {" "}
                          Enrollment Report
                          <Link
                            to="#"
                            class={`pull-right ${
                              downloadLoading ? "btn-opacity" : ""
                            }`}
                            onClick={() => download(searchFormik.values)}
                          >
                            {downloadLoading ? (
                              <span>
                                {" "}
                                <i class="fa fa-spinner fa-spin"></i>Export
                              </span>
                            ) : (
                              " Export"
                            )}
                          </Link>
                        </h4>
                      </div>

                      <div className="card-body table-responsive">
                        <table
                          className="table table-striped mb_01"
                          id="table-to-xls"
                        >
                          <thead>
                            <tr>
                              <th>S.No.</th>
                              <th>ZonalHead</th>
                              <th>StateHead</th>
                              <th>SalesTeamName</th>
                              <th>Mason Name</th>
                              <th>MobileNumber</th>
                              <th>EmailId</th>
                              <th>IDType</th>
                              <th>IDNumber</th>
                              <th>IDFrontImage</th>
                              <th>IDBackImage</th>
                              <th>Address1</th>
                              <th>Address2</th>
                              <th>Address3</th>
                              <th>City</th>
                              <th>State</th>
                              <th>DateOfRegistration</th>
                              <th>RegisterType</th>
                              <th>Status</th>
                              <th>RejectionReason</th>
                              <th>AdminName</th>
                              <th>AdminApprovalDate</th>
                              <th>ApprovedBy</th>
                              <th>salesApprovalDate</th>
                              <th>TotalEarnedPoints</th>
                              <th>TotalRedeemedPoints</th>
                              <th>BalancePoints</th>
                              <th>FirstScanDate</th>
                              <th>AadharNumber</th>
                              <th>MobileNumberLinkedWithAadhar</th>
                              <th>AadharFrontImage</th>
                              <th>AadharBackImage</th>
                              <th>Image Click By Sales Team </th>
                            </tr>
                          </thead>
                          {searchRecord === null ? (
                            <tbody>
                              <tr>
                                <td
                                  colSpan="33"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "16px",
                                  }}
                                >
                                  No record found!
                                </td>
                              </tr>
                            </tbody>
                          ) : (
                            <tbody>
                              {results &&
                                results.map((item, index) => {
                                  const dateOfRegistration = moment(
                                    item.createDateTime,
                                    "DD-MM-YYYY HH:mm"
                                  ).format("DD-MM-YYYY");
                                  const actiondate = item.approval
                                    ? moment(
                                        item.approval?.createDateTime,
                                        "DD-MM-YYYY HH:mm"
                                      ).format("DD-MM-YYYY")
                                    : "";
                                  const serialNumber =
                                    (currentPage - 1) * limit + index + 1;
                                  const isAadharFrontUrlAvailable =
                                    item.userModelKYC?.aadharFrontUrl &&
                                    item.userModelKYC?.aadharFrontUrl.trim() !==
                                      "";
                                  const isAadharBackUrlAvailable =
                                    item.userModelKYC?.aadharBackUrl &&
                                    item.userModelKYC?.aadharBackUrl.trim() !==
                                      "";
                                  const firstScanDate = item.firstScan
                                    ? moment(item.firstScan).format(
                                        "DD-MM-YYYY"
                                      )
                                    : "";

                                  const isIDBacktUrlAvailable =
                                    item.identityCardBackUrl &&
                                    item.identityCardBackUrl.trim() !== "";
                                  const isIDFrontUrlAvailable =
                                    item.identityCardFrontUrl &&
                                    item.identityCardFrontUrl.trim() !== "";
                                  const adminApprovalDate =
                                    item.adminApprovalDate
                                      ? moment(item.adminApprovalDate).format(
                                          "DD-MM-YYYY"
                                        )
                                      : "";

                                  const salesApprovalDate =
                                    item.salesApprovalDate
                                      ? moment(item.salesApprovalDate).format(
                                          "DD-MM-YYYY"
                                        )
                                      : "";

                                  return (
                                    <>
                                      <tr key={index}>
                                        <td>{serialNumber}</td>
                                        <td>{item.roName}</td>
                                        <td>{item.shName}</td>
                                        <td>{item.parentName}</td>
                                        <td>{item.name}</td>
                                        <td>{item.mobile}</td>
                                        <td>{item.email}</td>
                                        <td>{item.identityType}</td>
                                        <td>{item.identityNumber}</td>
                                        <td>
                                          {isIDFrontUrlAvailable ? (
                                            <Link
                                              to={item.identityCardFrontUrl}
                                              target="_blank"
                                              className="select"
                                            >
                                              View
                                            </Link>
                                          ) : (
                                            "N/A"
                                          )}
                                        </td>
                                        <td>
                                          {isIDBacktUrlAvailable ? (
                                            <Link
                                              to={item.identityCardBackUrl}
                                              target="_blank"
                                              className="select"
                                            >
                                              View
                                            </Link>
                                          ) : (
                                            "N/A"
                                          )}
                                        </td>
                                        <td>
                                          {item.addressWork?.workAddress1}
                                        </td>
                                        <td>
                                          {item.addressWork?.workAddress2}
                                        </td>
                                        <td>
                                          {item.addressWork?.workAddress3}
                                        </td>
                                        <td>{item.addressWork?.workCity}</td>
                                        <td>{item.addressWork?.workState}</td>
                                        <td>{dateOfRegistration}</td>
                                        <td>{item.registerFrom}</td>
                                        <td>{item.status}</td>
                                        <td>{item.approval?.reason}</td>
                                        <td>{item.adminName}</td>
                                        <td>{adminApprovalDate}</td>
                                        <td>{item.approvedBy}</td>
                                        <td>{item.salesApprovalDate}</td>
                                        <td>{item.totalPoints}</td>
                                        <td>{item.redeemPoints}</td>
                                        <td>{item.points}</td>
                                        <td>{firstScanDate}</td>
                                        <td>
                                          {item.userModelKYC?.aadharNumber}
                                        </td>
                                        <td>
                                          {item.userModelKYC?.aadharMobile}
                                        </td>
                                        <td>
                                          {isAadharFrontUrlAvailable ? (
                                            <Link
                                              to={
                                                item.userModelKYC
                                                  ?.aadharFrontUrl
                                              }
                                              target="_blank"
                                              className="select"
                                            >
                                              View
                                            </Link>
                                          ) : (
                                            "N/A"
                                          )}
                                        </td>
                                        <td>
                                          {isAadharBackUrlAvailable ? (
                                            <Link
                                              to={
                                                item.userModelKYC?.aadharBackUrl
                                              }
                                              target="_blank"
                                              className="select"
                                            >
                                              View
                                            </Link>
                                          ) : (
                                            "N/A"
                                          )}
                                        </td>
                                        <td>
                                          {item.profileVerify
                                            ? "True"
                                            : "False"}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </tbody>
                          )}
                        </table>
                        {totalPages > 1 && (
                          <ResponsivePagination
                            current={currentPage}
                            total={totalPages}
                            onPageChange={(page) => {
                              setCurrentPage(page);
                              getReportData(filterData, page);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
